import createAction from '../../common/services/create-action';
import { POST_REMOVE_SOLVED_COMMENT } from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';

export const REMOVE_BEST_ANSWER_REQUEST = 'post/REMOVE_BEST_ANSWER_REQUEST';
export const REMOVE_BEST_ANSWER_SUCCESS = 'post/REMOVE_BEST_ANSWER_SUCCESS';
export const REMOVE_BEST_ANSWER_FAILURE = 'post/REMOVE_BEST_ANSWER_FAILURE';

const removeBestAnswerRequest = createAction(REMOVE_BEST_ANSWER_REQUEST);
const removeBestAnswerSuccess = createAction(REMOVE_BEST_ANSWER_SUCCESS);
const removeBestAnswerFailure = createAction(REMOVE_BEST_ANSWER_FAILURE);

export function removeBestAnswer(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(removeBestAnswerRequest());

    const promise = request.delete(`/posts/${postId}/best-answer`);
    return promise
      .then(
        (post) => dispatch(removeBestAnswerSuccess(post)),
        () => dispatch(removeBestAnswerFailure()),
      )
      .then(() => promise);
  };
}

export function removeBestAnswerAndNotify(postId) {
  return (dispatch) =>
    dispatch(removeBestAnswer(postId)).then(() =>
      dispatch(showMessage(POST_REMOVE_SOLVED_COMMENT)),
    );
}
