import { createAction } from '@reduxjs/toolkit';

import { getPostMap } from '../../selectors/post-selectors';
import { Post } from '../../types/forum-post';
import { AppThunk } from '../../types/store-types';

/*
 * mark POSTS as read actions
 * */

export const MARK_POSTS_AS_READ_REQUEST = 'MARK_POSTS_AS_READ_REQUEST';
export const MARK_POSTS_AS_READ_SUCCESS = 'MARK_POSTS_AS_READ_SUCCESS';
export const MARK_POSTS_AS_READ_FAILURE = 'MARK_POSTS_AS_READ_FAILURE';

const markPostsAsReadRequest = createAction<{ postIds: string[] | null }>(
  MARK_POSTS_AS_READ_REQUEST,
);
const markPostsAsReadSuccess = createAction<{
  postIds: string[] | null;
  categoryId: string;
}>(MARK_POSTS_AS_READ_SUCCESS);
const markPostsAsReadFailure = createAction(MARK_POSTS_AS_READ_FAILURE);

export const markPostsAsRead = (categoryId: string): AppThunk => {
  return (dispatch, getState, { request }) => {
    const state = getState();
    let postIds: string[] | null = null;

    if (categoryId) {
      postIds = Object.values<Post>(getPostMap(state))
        .filter((p) => p.categoryId === categoryId)
        .map((p) => p._id);
    }

    dispatch(markPostsAsReadRequest({ postIds }));

    const promise = request.put(`/platformized/v1/categories/mark-posts-read`, { categoryId });

    return promise
      .then(
        () => dispatch(markPostsAsReadSuccess({ categoryId, postIds })),
        () => dispatch(markPostsAsReadFailure()),
      )
      .then(() => promise);
  };
};

type MarkPostsAsReadTypes =
  | ReturnType<typeof markPostsAsReadRequest>
  | ReturnType<typeof markPostsAsReadSuccess>
  | ReturnType<typeof markPostsAsReadFailure>;

/*
 * mark POST as read actions
 * */

export const MARK_POST_AS_READ_REQUEST = 'post/MARK_POST_AS_READ_REQUEST';
export const MARK_POST_AS_READ_SUCCESS = 'post/MARK_POST_AS_READ_SUCCESS';
export const MARK_POST_AS_READ_FAILURE = 'post/MARK_POST_AS_READ_FAILURE';

const markPostAsReadRequest = createAction<{ postId: string }>(MARK_POST_AS_READ_REQUEST);
const markPostAsReadSuccess = createAction<{ postId: string }>(MARK_POST_AS_READ_SUCCESS);
const markPostAsReadFailure = createAction<{ postId: string }, any>(MARK_POST_AS_READ_FAILURE);

export const markPostAsRead = (postId: string): AppThunk => {
  return (dispatch, getState, { request }) => {
    dispatch(markPostAsReadRequest({ postId }));

    const promise = request.put(`/platformized/v1/posts/${postId}/mark-read`);
    return promise
      .then(
        () => dispatch(markPostAsReadSuccess({ postId })),
        () => dispatch(markPostAsReadFailure({ postId })),
      )
      .then(() => promise);
  };
};

type MarkPostAsReadTypes =
  | ReturnType<typeof markPostAsReadRequest>
  | ReturnType<typeof markPostAsReadSuccess>
  | ReturnType<typeof markPostAsReadFailure>;

/*
 * mark POST as unread actions
 * */

export const MARK_POST_AS_UNREAD_REQUEST = 'post/MARK_POST_AS_UNREAD_REQUEST';
export const MARK_POST_AS_UNREAD_SUCCESS = 'post/MARK_POST_AS_UNREAD_SUCCESS';
export const MARK_POST_AS_UNREAD_FAILURE = 'post/MARK_POST_AS_UNREAD_FAILURE';

const markPostAsUnreadRequest = createAction<{ postId: string }>(MARK_POST_AS_UNREAD_REQUEST);
const markPostAsUnreadSuccess = createAction<{ postId: string }>(MARK_POST_AS_UNREAD_SUCCESS);
const markPostAsUnreadFailure = createAction<{ postId: string }>(MARK_POST_AS_UNREAD_FAILURE);

export const markPostAsUnread = (postId: string): AppThunk => {
  return (dispatch, getState, { request }) => {
    dispatch(markPostAsUnreadRequest({ postId }));

    const promise = request.put(`/platformized/v1/posts/${postId}/mark-unread`);
    return promise
      .then(
        () => dispatch(markPostAsUnreadSuccess({ postId })),
        () => dispatch(markPostAsUnreadFailure({ postId })),
      )
      .then(() => promise);
  };
};

type MarkPostAsUnreadTypes =
  | ReturnType<typeof markPostAsUnreadRequest>
  | ReturnType<typeof markPostAsUnreadSuccess>
  | ReturnType<typeof markPostAsUnreadFailure>;

/*
 * mark POST as seen actions
 * */

export const MARK_POSTS_AS_SEEN_REQUEST = 'post/MARK_POSTS_AS_SEEN_REQUEST';
export const MARK_POSTS_AS_SEEN_SUCCESS = 'post/MARK_POSTS_AS_SEEN_SUCCESS';
export const MARK_POSTS_AS_SEEN_FAILURE = 'post/MARK_POSTS_AS_SEEN_FAILURE';

const markPostsAsSeenRequest = createAction<{ postsIds: string[] }>(MARK_POSTS_AS_SEEN_REQUEST);
const markPostsAsSeenSuccess = createAction<{ postsIds: string[] }>(MARK_POSTS_AS_SEEN_SUCCESS);
const markPostsAsSeenFailure = createAction<{ postsIds: string[] }>(MARK_POSTS_AS_SEEN_FAILURE);

export const markPostsAsSeen = (postsIds: string[]): AppThunk => {
  return (dispatch, getState, { request }) => {
    dispatch(markPostsAsSeenRequest({ postsIds }));

    const promise = request.put('/platformized/v1/posts/batch/mark-seen', { postsIds });
    return promise
      .then(
        () => dispatch(markPostsAsSeenSuccess({ postsIds })),
        () => dispatch(markPostsAsSeenFailure({ postsIds })),
      )
      .then(() => promise);
  };
};

type MarkPostsAsSeenType =
  | ReturnType<typeof markPostsAsSeenRequest>
  | ReturnType<typeof markPostsAsSeenSuccess>
  | ReturnType<typeof markPostsAsSeenFailure>;

/*
 * increase POST view counter actions
 * */

export const MARK_POSTS_VIEWED_REQUEST = 'post/MARK_POSTS_VIEWED_REQUEST';
export const MARK_POSTS_VIEWED_SUCCESS = 'post/MARK_POSTS_VIEWED_SUCCESS';
export const MARK_POSTS_VIEWED_FAILURE = 'post/MARK_POSTS_VIEWED_FAILURE';

const markPostsViewedRequest = createAction<{ postId: string }>(MARK_POSTS_VIEWED_REQUEST);
const markPostsViewedSuccess = createAction<{ postId: string }>(MARK_POSTS_VIEWED_SUCCESS);
const markPostsViewedFailure = createAction<{ postId: string }>(MARK_POSTS_VIEWED_FAILURE);

export const markPostViewed = (postId: string): AppThunk => {
  return (dispatch, getState, { request }) => {
    dispatch(markPostsViewedRequest({ postId }));

    const promise = request.put(`/platformized/v1/posts/${postId}/view`);
    return promise
      .then(
        () => dispatch(markPostsViewedSuccess({ postId })),
        () => dispatch(markPostsViewedFailure({ postId })),
      )
      .then(() => promise);
  };
};

type MarkPostsViewedType =
  | ReturnType<typeof markPostsViewedRequest>
  | ReturnType<typeof markPostsViewedSuccess>
  | ReturnType<typeof markPostsViewedFailure>;

// All types

export type UserActivityTypes =
  | MarkPostsAsReadTypes
  | MarkPostAsReadTypes
  | MarkPostAsUnreadTypes
  | MarkPostsAsSeenType
  | MarkPostsViewedType;
