import { get, omit } from 'lodash';

import { SEARCH_CLEAR_RESULTS } from '../actions/search';
import { FETCH_SEARCH_FAILURE, FETCH_SEARCH_SUCCESS } from '../actions/fetch-search';
import {
  INCREMENT_POST_LIKE_COUNT_FAILURE,
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../../common/actions/increment-post-like-count';
import {
  INCREMENT_COMMENT_LIKE_COUNT_FAILURE,
  INCREMENT_COMMENT_LIKE_COUNT_REQUEST,
  INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
} from '../actions/increment-comment-like-count';
import { MOVE_POST_SUCCESS } from '../actions/move-post';
import { PIN_POST_SUCCESS } from '../actions/pin-post';
import { UNPIN_POST_SUCCESS } from '../actions/unpin-post';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../actions/enable-post-comments';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../actions/disable-post-comments';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { DELETE_COMMENT_SUCCESS } from '../actions/delete-comment';

const initialState = {
  result: [],
  metadata: {
    offset: 0,
    size: 0,
    total: 0,
  },
};

// eslint-disable-next-line complexity
const post = (state, action) => {
  switch (action.type) {
    case MOVE_POST_SUCCESS:
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      if (state._id === action.payload._id) {
        return { ...action.payload, type: 'post' };
      }
      return state;
    case INCREMENT_POST_LIKE_COUNT_SUCCESS:
    case INCREMENT_COMMENT_LIKE_COUNT_SUCCESS:
      if (state._id === action.payload._id) {
        return omit({ ...state, ...action.payload }, 'isLikeInProgress');
      }
      return state;
    case INCREMENT_COMMENT_LIKE_COUNT_REQUEST:
    case INCREMENT_POST_LIKE_COUNT_REQUEST: {
      if (state._id === action.payload._id) {
        return {
          ...state,
          isLiked: !state.isLiked,
          likeCount: state.likeCount + (state.isLiked ? -1 : 1),
          isLikeInProgress: true,
        };
      }
      return state;
    }
    case INCREMENT_COMMENT_LIKE_COUNT_FAILURE:
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
      if (state._id === action.payload._id) {
        return omit(
          {
            ...state,
            isLiked: !state.isLiked,
            likeCount: state.likeCount + (state.isLiked ? -1 : 1),
          },
          'isLikeInProgress',
        );
      }
      return state;
    default:
      return state;
  }
};

const setResult = (state, action) => {
  return {
    ...state,
    result: state.result.map((entity) => {
      if (entity._id === action.payload._id) {
        return post(entity, action);
      }
      return entity;
    }),
  };
};

const setCommentPost = (state, action) => {
  // handle post object in comments
  return {
    ...state,
    result: state.result.map((entity) => {
      if (get(entity, 'post._id') === action.payload._id) {
        return { ...entity, post: post(entity.post, action) };
      }
      return entity;
    }),
  };
};

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_SUCCESS:
      return { ...state, result: action.payload.posts, metadata: action.payload.metadata };
    case FETCH_SEARCH_FAILURE:
    case SEARCH_CLEAR_RESULTS:
      return { ...state, result: [] };
    case DELETE_POST_SUCCESS:
      return { ...state, result: state.result.filter((post) => post._id !== action.payload) };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        result: state.result.filter((comment) => comment._id !== action.payload._id),
      };
    case MOVE_POST_SUCCESS:
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
    case INCREMENT_POST_LIKE_COUNT_REQUEST:
    case INCREMENT_COMMENT_LIKE_COUNT_REQUEST:
    case INCREMENT_COMMENT_LIKE_COUNT_FAILURE:
    case INCREMENT_COMMENT_LIKE_COUNT_SUCCESS:
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
    case INCREMENT_POST_LIKE_COUNT_SUCCESS: {
      const state1 = setResult(state, action);
      return setCommentPost(state1, action);
    }
    default:
      return state;
  }
};
