import {
  FETCH_SITE_MEMBERS_LIST_SUCCESS,
  SITE_MEMBERS_RESET,
  FETCH_SITE_MEMBERS_LIST_REQUEST,
} from './site-members-actions';
import { ASSIGN_MEMBERS_TO_GROUP_SUCCESS } from '../../../app/actions/assign-members-to-group';
import { DELETE_MEMBERS_FROM_GROUP_SUCCESS } from '../../../app/actions/delete-members-from-group';
import {
  MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_ADD,
  MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_REMOVE,
} from '../../../app/actions/manage-category-members';

const initialState = { members: {}, membersTotal: {}, fetching: {}, membersEntitiesById: {} };

export function siteMembers(state = initialState, action) {
  switch (action.type) {
    case FETCH_SITE_MEMBERS_LIST_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.payload.query]: [
            ...(state.fetching[action.payload.query] || []),
            action.payload.offset,
          ],
        },
      };
    case FETCH_SITE_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        membersEntitiesById: {
          ...state.membersEntitiesById,
          ...action.payload.members.reduce((result, member) => {
            result[member.id] = member;
            return result;
          }, {}),
        },
        members: {
          ...state.members,
          [action.payload.query]: {
            ...(state.members[action.payload.query] || {}),
            [action.payload.pagination.offset]: action.payload.members.map((m) => m.id),
          },
        },
        membersTotal: {
          ...state.membersTotal,
          [action.payload.query]: action.payload.pagination.total,
        },
        fetching: {
          ...state.fetching,
          [action.payload.query]: state.fetching[action.payload.query].filter(
            (index) => index !== action.payload.pagination.offset,
          ),
        },
      };
    case SITE_MEMBERS_RESET:
      return initialState;
    case MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_ADD:
    case ASSIGN_MEMBERS_TO_GROUP_SUCCESS: {
      const members = { ...state.membersEntitiesById };
      for (const id of action.payload.listOfSiteMemberIds) {
        members[id].groups = members[id].groups.concat(action.payload.groupId);
      }
      return {
        ...state,
        membersEntitiesById: members,
      };
    }

    case MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_REMOVE:
    case DELETE_MEMBERS_FROM_GROUP_SUCCESS: {
      const members = { ...state.membersEntitiesById };
      for (const id of action.payload.listOfSiteMemberIds) {
        members[id].groups = members[id].groups.filter((group) => group !== action.payload.groupId);
      }
      return {
        ...state,
        membersEntitiesById: members,
      };
    }

    default:
      return state;
  }
}
