import { DELETE_POST } from '../../../constants/interactions';
import { POST_DELETE } from '../../messages/message-types';
import { deletePost } from '../../../actions/delete-post';
import { getPost } from '../../../selectors/post-selectors';
import { getCategory } from '../../../../common/selectors/categories-selectors';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';
import { isExperimentEnabled } from '../../../selectors/experiments-selectors';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons';
import { getTabsOrigin, getTabsReferrer } from '../../../containers/header-navigation';
import { POST_PAGE } from '../../../services/detect-route';
import { getFeedUrl } from '../../../containers/header-navigation/get-feed-url';
import { getIsMainPageEnabled } from '../../../selectors/app-settings-selectors';

export const deletePostModalResolver =
  (postId, { showMessage }) =>
  (dispatch, getState, { fedopsLogger }) => {
    fedopsLogger.interactionStarted(DELETE_POST);
    const state = getState();

    const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);
    const post = getPost(state, postId);
    const category = getCategory(state, post.categoryId);
    let destination = `/${category.slug}`;

    if (isForumTabsEnabled) {
      const origin = getTabsOrigin(state);
      const isMainPageCategoriesList = getIsMainPageEnabled(state, state);

      if (origin === POST_PAGE) {
        const referrer = getTabsReferrer(state);
        destination = getFeedUrl(referrer, isMainPageCategoriesList) || destination;
      } else {
        destination = getFeedUrl(origin, isMainPageCategoriesList) || destination;
      }
    }

    dispatch(navigateWithinForum(destination));

    return dispatch(deletePost(postId)).then(() => showMessage(POST_DELETE));
  };
